import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserDto } from '../models/UserDto';

export const NachaAdminUser = 'Nacha Admin';
export const NachaUser = 'Nacha User';
export const OdfiAdmin = 'ODFI Admin';
export const RdfiAdmin = 'RDFI Admin';
export const OdfiUser = 'ODFI User';
export const RdfiUser = 'RDFI User';
export const TpspAdmin = 'TPSP Admin';
export const TpspUser = 'TPSP User';
export const TpsAdmin = 'TPS Admin';
export const TpsUser = 'TPS User';
export const PaAdmin = 'PA Admin';
export const PaUser = 'PA User';
export const AchOperatorAdmin = 'ACH Operator Admin';
export const AchOperatorUser = 'ACH Operator User';
export const FiRoles = [OdfiAdmin, OdfiUser, RdfiAdmin, RdfiUser];

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user$: BehaviorSubject<UserDto | null> = new BehaviorSubject<UserDto | null>(
    null,
  );

  isNachaAdmin = () =>
    this.user$.value?.userRole.toLowerCase() == NachaAdminUser.toLowerCase();
  isNachaUser = () =>
    this.user$.value?.userRole.toLowerCase() == NachaUser.toLowerCase();
  isOdfiAdmin = () =>
    this.user$.value?.userRole.toLowerCase() == OdfiAdmin.toLowerCase();
  isOdfiUser = () =>
    this.user$.value?.userRole.toLowerCase() == OdfiUser.toLowerCase();
  isRdfiAdmin = () =>
    this.user$.value?.userRole.toLowerCase() == RdfiAdmin.toLowerCase();
  isRdfiUser = () =>
    this.user$.value?.userRole.toLowerCase() == RdfiUser.toLowerCase();
  isTpspAdmin = () =>
    this.user$.value?.userRole.toLowerCase() == TpspAdmin.toLowerCase();
  isTpspUser = () =>
    this.user$.value?.userRole.toLowerCase() == TpspUser.toLowerCase();
  isTpsAdmin = () =>
    this.user$.value?.userRole.toLowerCase() == TpsAdmin.toLowerCase();
  isTpsUser = () =>
    this.user$.value?.userRole.toLowerCase() == TpsUser.toLowerCase();
  isPaAdmin = () =>
    this.user$.value?.userRole.toLowerCase() == PaAdmin.toLowerCase();
  isPaUser = () =>
    this.user$.value?.userRole.toLowerCase() == PaUser.toLowerCase();
  isAchOperatorAdmin = () =>
    this.user$.value?.userRole.toLowerCase() == AchOperatorAdmin.toLowerCase();
  isAchOperatorUser = () =>
    this.user$.value?.userRole.toLowerCase() == AchOperatorUser.toLowerCase();
  isOdfi = () => this.user$.value?.userRole.toLowerCase().indexOf('odfi') != -1;
  isRdfi = () => this.user$.value?.userRole.toLowerCase().indexOf('rdfi') != -1;
  isTps = () => this.user$.value?.userRole.toLowerCase().indexOf('tps') != -1;
  isTpsp = () => this.user$.value?.userRole.toLowerCase().indexOf('tpsp') != -1;
  isPa = () => this.user$.value?.userRole.toLowerCase().indexOf('pa') != -1;
  isAchOperator = () =>
    this.user$.value?.userRole.toLowerCase().indexOf('ach operator') != -1;
  isNacha = () =>
    this.user$.value?.userRole.toLowerCase().indexOf('nacha') != -1;
  isAdmin = () =>
    this.isOdfiAdmin() || this.isNachaAdmin() || this.isRdfiAdmin();
  isFiAdmin = () =>
    this.isOdfiAdmin() ||
    this.isRdfiAdmin() ||
    this.isTpspAdmin() ||
    this.isTpsAdmin() ||
    this.isPaAdmin() ||
    this.isAchOperatorAdmin();
  getFiId = () => this.user$.value?.fiId;
  getFiName = () => this.user$.value?.fiName;
  getUserFullName = () => this.user$.value?.fullName;
  getUserName = () => this.user$.value?.userName;
  getTaxId = () => this.user$.value?.taxId;
  getPrimaryRoutingNumber = () => this.user$.value?.primaryRoutingNumber;
  //TODO: include other FIs
  isFi = () =>
    this.isRdfi() ||
    this.isOdfi() ||
    this.isTps() ||
    this.isTpsp() ||
    this.isPa() ||
    this.isAchOperator();
  isRdfiOrOdfi = () => this.isRdfi() || this.isOdfi();

  isTermsAccepted = () => this.user$.value?.isTermsAccepted ?? true;
}
