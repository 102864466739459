import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { TableComponent } from './table/table.component';
import { HorizontalLineComponent } from './horizontal-line/horizontal-line.component';
import { ParagraphComponent } from './paragraph/paragraph.component';
import { BadgeComponent } from './badge/badge.component';
import { ButtonComponent } from './button/button.component';
import { SearchComponent } from './search/search.component';
import { PaginationComponent } from './pagination/pagination.component';
import { WizardComponent } from './wizard/wizard.component';
import { WizardCommonComponent } from './wizard-common/wizard-common.component';
import { FilterComponent } from './filter/filter.component';
import { AccordionComponent } from './accordion/accordion.component';
import { ModalComponent } from './modal/modal.component';
import { ToastComponent } from './toast/toast.component';
import { AlertComponent } from './alert/alert.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { AlphabateOnlyDirective } from 'src/app/directives/alphabate-only.directive';
import { FaxNumberDirective } from 'src/app/directives/fax-number.directive';
import { PhoneNumberDirective } from 'src/app/directives/phone-number.directive';
import { EmailDirective } from 'src/app/directives/email.directive';
import { GridRowHoverBgDirective } from 'src/app/directives/grid-row-hover-bg.directive';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { GoogleAddressComponent } from './google-address/google-address.component';
import { AlertMessageComponent } from './alert-message/alert-message.component';
import { MessagePanelComponent } from './message-panel/message-panel.component';
import { FileDropComponent } from './file-drop/file-drop.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UploadInstructionsComponent } from './upload-instructions/upload-instructions.component';
import { TruncatePipe } from 'src/app/pipes/truncate.pipe';
import { SplitDateTimePipe } from 'src/app/pipes/split-date-time.pipe';
import { LocalDatePipe } from 'src/app/pipes/local-date-pipe';
import { TimeformatPipe } from 'src/app/pipes/timeformat.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { ColumnsToDisplayComponent } from './columns-to-display/columns-to-display.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { NoResultFoundComponent } from './no-result-found/no-result-found.component';
import { HighlightPipe } from 'src/app/pipes/highlight.pipe';
import { TodWizardComponent } from 'src/app/modules/common-components/tod-wizard/tod-wizard.component';
import { TaxIdDirective } from 'src/app/directives/tax-id.directive';
import { NumberonlyDirective } from 'src/app/directives/numberonly.directive';
import { DunNumberDirective } from 'src/app/directives/dun-number.directive';
import { PhonePatternDirective } from 'src/app/directives/phone-pattern.directive';
import { ValidateEmailDirective } from 'src/app/directives/validate-email.directive';
import { TelephoneWithExtensionDirective } from 'src/app/directives/telephone-with-extension.directive';
import { FaxNumberWithHyphenDirective } from 'src/app/directives/fax-number-with-hyphen.directive';
import { ValidateRtnDirective } from 'src/app/directives/validate-rtn.directive';
import { ValidateTaxIdDirective } from 'src/app/directives/validate-tax-id.directive';
import { InvalidPhoneNumberMsgComponent } from '../invalid-phone-number-msg/invalid-phone-number-msg.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { UtcDatePipe } from 'src/app/pipes/utc-date.pipe';
import { AlphaNumericDirective } from 'src/app/directives/alpha-numeric.directive';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { CalendarModule } from 'primeng/calendar';
import { PageTitleComponent } from './page-title/page-title.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CommentsComponent } from './comment/comments.component';
import { RecaptchaModule } from 'ng-recaptcha-2';
import { NumberInputRestrictionDirective } from 'src/app/directives/number-input-restriction.directive';
import { CommaSeparatedNumberPipe } from 'src/app/pipes/comma-separated-number.pipe';
import { InputErrorPanelComponent } from 'src/app/modules/common-components/input-error-panel/input-error-panel.component';
import { AuthHeaderComponent } from '../portal-registration/auth-header/auth-header.component';
import { AppFooterComponent } from 'src/app/app-footer/app-footer.component';
import { TouComponent } from './tou/tou.component';
import { NamePatternDirective } from 'src/app/directives/name-pattern.directive';
import { PreventSpaceDirective } from 'src/app/directives/prevent-space.directive';
import { UserSessionModalComponent } from './user-session-modal/user-session-modal.component';
import { AutocompleteDirective } from 'src/app/directives/autocomplete.directive';
import { GoogleAddressInfoComponent } from './google-address-info/google-address-info.component';
import { CharacterCountDirective } from 'src/app/directives/character-count.directive';
import { InputNumberModule } from 'primeng/inputnumber';
import { SePreviewHtmlMessageComponent } from './se-preview-html-message/se-preview-html-message.component';
import { TabsComponent } from './tabs/tabs.component';
import { SkeletonModule } from 'primeng/skeleton';
import { WidthDirective } from 'src/app/directives/width.directive';
import { DateTimeZonePipe } from 'src/app/pipes/date-time-zone.pipe';
import { CapsuleComponent } from './capsule/capsule.component';
import { WhiteBoardComponent } from './white-board/white-board.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { AccordionModule } from 'primeng/accordion';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipModule } from 'primeng/chip';
import { NgxEditorModule } from 'ngx-editor';
import { DateAgoPipe } from 'src/app/pipes/date-ago.pipe';
import { ScrollDirective } from 'src/app/directives/scroll.directive';
import { TableModule } from 'primeng/table';

@NgModule({
  declarations: [
    TableComponent,
    HorizontalLineComponent,
    ParagraphComponent,
    BadgeComponent,
    SearchComponent,
    ButtonComponent,
    PageTitleComponent,
    PaginationComponent,
    SearchComponent,
    WizardComponent,
    WizardCommonComponent,
    FilterComponent,
    AccordionComponent,
    ModalComponent,
    ToastComponent,
    AlertComponent,
    SpinnerComponent,
    ClickOutsideDirective,
    AlphabateOnlyDirective,
    FaxNumberDirective,
    PhoneNumberDirective,
    EmailDirective,
    NumberonlyDirective,
    GridRowHoverBgDirective,
    ContextMenuComponent,
    GoogleAddressComponent,
    AlertMessageComponent,
    MessagePanelComponent,
    FileDropComponent,
    UploadInstructionsComponent,
    TruncatePipe,
    SplitDateTimePipe,
    LocalDatePipe,
    DateTimeZonePipe,
    TimeformatPipe,
    ColumnsToDisplayComponent,
    TimePickerComponent,
    NoResultFoundComponent,
    HighlightPipe,
    TodWizardComponent,
    TaxIdDirective,
    DunNumberDirective,
    PhonePatternDirective,
    ValidateEmailDirective,
    TelephoneWithExtensionDirective,
    FaxNumberWithHyphenDirective,
    ValidateRtnDirective,
    ValidateTaxIdDirective,
    InvalidPhoneNumberMsgComponent,
    UtcDatePipe,
    AlphaNumericDirective,
    DateFilterComponent,
    CommentsComponent,
    NumberInputRestrictionDirective,
    CommaSeparatedNumberPipe,
    InputErrorPanelComponent,
    AuthHeaderComponent,
    AppFooterComponent,
    TouComponent,
    NamePatternDirective,
    PreventSpaceDirective,
    UserSessionModalComponent,
    AutocompleteDirective,
    GoogleAddressInfoComponent,
    CharacterCountDirective,
    SePreviewHtmlMessageComponent,
    TabsComponent,
    WidthDirective,
    CapsuleComponent,
    WhiteBoardComponent,
    InputSearchComponent,
    DateAgoPipe,
    ScrollDirective,
  ],
  providers: [UtcDatePipe, LocalDatePipe],
  imports: [
    CommonModule,
    SharedModule,
    NgxFileDropModule,
    DropdownModule,
    CalendarModule,
    InputTextareaModule,
    SplitButtonModule,
    RecaptchaModule,
    InputNumberModule,
    SkeletonModule,
    AccordionModule,
    ChipModule,
    MultiSelectModule,
    NgxEditorModule,
    TableModule,
  ],
  exports: [
    SharedModule,
    TableComponent,
    HorizontalLineComponent,
    BadgeComponent,
    SearchComponent,
    ButtonComponent,
    SearchComponent,
    PageTitleComponent,
    PaginationComponent,
    WizardComponent,
    WizardCommonComponent,
    FilterComponent,
    AccordionComponent,
    ModalComponent,
    ToastComponent,
    AlertComponent,
    SpinnerComponent,
    ContextMenuComponent,
    GoogleAddressComponent,
    AlertMessageComponent,
    MessagePanelComponent,
    FileDropComponent,
    ClickOutsideDirective,
    AlphabateOnlyDirective,
    FaxNumberDirective,
    PhoneNumberDirective,
    EmailDirective,
    NumberonlyDirective,
    GridRowHoverBgDirective,
    TruncatePipe,
    SplitDateTimePipe,
    LocalDatePipe,
    DateTimeZonePipe,
    TimeformatPipe,
    ColumnsToDisplayComponent,
    TimeformatPipe,
    TimePickerComponent,
    NoResultFoundComponent,
    HighlightPipe,
    TodWizardComponent,
    TaxIdDirective,
    DunNumberDirective,
    PhonePatternDirective,
    ValidateEmailDirective,
    TelephoneWithExtensionDirective,
    FaxNumberWithHyphenDirective,
    ValidateRtnDirective,
    ValidateTaxIdDirective,
    InvalidPhoneNumberMsgComponent,
    InputTextareaModule,
    UtcDatePipe,
    AlphaNumericDirective,
    DateFilterComponent,
    SplitButtonModule,
    CommentsComponent,
    RecaptchaModule,
    NumberInputRestrictionDirective,
    CommaSeparatedNumberPipe,
    InputErrorPanelComponent,
    AuthHeaderComponent,
    AppFooterComponent,
    TouComponent,
    NamePatternDirective,
    PreventSpaceDirective,
    UserSessionModalComponent,
    AutocompleteDirective,
    GoogleAddressInfoComponent,
    CharacterCountDirective,
    CalendarModule,
    InputNumberModule,
    SePreviewHtmlMessageComponent,
    TabsComponent,
    SkeletonModule,
    WidthDirective,
    CapsuleComponent,
    WhiteBoardComponent,
    InputSearchComponent,
    DropdownModule,
    AccordionModule,
    ChipModule,
    MultiSelectModule,
    NgxEditorModule,
    DateAgoPipe,
    ScrollDirective,
  ],
})
export class CommonComponentsModule {}
