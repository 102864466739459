import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'toDateTimeZone',
})
export class DateTimeZonePipe implements PipeTransform {
  transform(
    value: string,
    timeZone: string,
    format: any = 'MM/DD/YYYY, hh:mm A zzz',
  ): string {
    // Check if the input value is a valid date
    if (!value || !moment(value).isValid()) {
      return '-';
    }

    const splitedDateTime = moment(value).format(format).split(', ');
    return `${splitedDateTime[0]}<br>${splitedDateTime[1]}${timeZone}`;
  }
}
