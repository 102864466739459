import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ToastEvent } from './modules/common-components/toast/models/toast-event';
import { ToastService } from './modules/common-components/toast/services/toast.service';
import { AuthenticationService } from 'src/app/infrastructure/authentication/authentication.service';
import { ModalService } from './services/modal.service';
import { TokenStorageService } from './infrastructure/authentication/token-storage.service';
import { MessagePanelService } from './modules/common-components/message-panel/services/message-panel.service';
import { EventTypes } from './modules/common-components/toast/models/event-types';
import { GoogleService } from './modules/common-components/google-address/services/google.service';
import { GeneralService } from './services/general.service';
import { Subscription, filter, take } from 'rxjs';
import { UserService } from './services/user.service';
import { UserSessionService } from './infrastructure/authentication/user-session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'rmp-app';
  currentToasts: ToastEvent[] = [];
  isLoggedIn = false;
  restrictedRoutes: string[] = ['/faq'];
  isRouteRestricted = false;
  isModalActive = false;
  closeIconVisible = false;
  panelmessages: string[] = [];
  showAllMessages = false;
  messageDisplay = false;
  messageIconType: EventTypes = EventTypes.Success;
  isTermsAccepted = true;
  userSubscription: Subscription = new Subscription();
  userSessionSubscription: Subscription = new Subscription();

  constructor(
    public userService: UserService,
    private toastService: ToastService,
    private cdr: ChangeDetectorRef,
    public modalService: ModalService,
    private tokenStorage: TokenStorageService,
    private messagePanelService: MessagePanelService,
    private googleService: GoogleService,
    private generalService: GeneralService,
    private authService: AuthenticationService,
    private userSessionService: UserSessionService,
  ) {}

  ngOnInit() {
    this.authService.Initialize();
    if (this.restrictedRoutes.indexOf(window.location.pathname) >= 0) {
      this.isRouteRestricted = true;
    }

    this.LoadGoogleTagManager();
    this.SubscribeToToasts();
    this.SubscribeToMessages();

    this.authService.isLoggedIn$
      .pipe(
        filter((isLoggedIn: boolean) => isLoggedIn),
        take(1),
      )
      .subscribe(() => {
        this.InitSessionTimer();
        this.generalService.GetAllMessage();
        this.generalService.GetMsalConfigs();
        this.generalService.GetAllPageContents();
      });
    this.authService.isLoggedIn$.subscribe(
      (loginFlag) => (this.isLoggedIn = loginFlag),
    );

    this.userSubscription.add(
      this.userService.user$.subscribe((user) => {
        if (user) {
          this.isTermsAccepted = user.isTermsAccepted;
        }
      }),
    );
  }

  private InitSessionTimer() {
    this.userSessionService.StartSessionCountdown();
  }

  private async LoadGoogleTagManager(): Promise<void> {
    const gtmId = await this.googleService.GetGoogleTagManagerId();

    if (gtmId) {
      const gtmScript = document.createElement('script');
      gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${gtmId}');`;

      document.head.appendChild(gtmScript);

      const gtmNoScript = document.createElement('noscript');
      gtmNoScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;

      document.body.appendChild(gtmNoScript);
    }
  }

  SubscribeToToasts() {
    this.toastService.toastEvents.subscribe((toasts) => {
      const currentToast: ToastEvent = {
        type: toasts.type,
        title: toasts.title,
        message: toasts.message,
      };
      this.currentToasts.push(currentToast);
      this.cdr.detectChanges();
    });
  }

  SubscribeToMessages() {
    this.panelmessages = [];
    this.messagePanelService.messageEvents.subscribe((messageEvent) => {
      if (messageEvent.message) {
        this.panelmessages.push(messageEvent.message);
      }
      this.messageIconType = EventTypes.Error;
      this.showAllMessages = true;
      this.messageDisplay = true;
      this.closeIconVisible = true;
      setTimeout(() => {
        this.messageDisplay = false;
        this.closeIconVisible = false;
        this.panelmessages = [];
      }, 10000);
      this.panelmessages = this.panelmessages.filter((v) => {
        return v;
      });
      setTimeout(() => {
        this.messageDisplay = false;
        this.closeIconVisible = false;
        this.panelmessages = [];
      }, 10000);
      this.cdr.detectChanges();
    });
  }

  Print() {
    this.generalService.GetTermsOfUserPdf().then((fileResponse) => {
      const blobUrl = URL.createObjectURL(fileResponse.data);
      window.open(blobUrl, '_blank');
    });
  }

  Accept() {
    this.generalService.UpdateTermsOfUseAccepted().then(() => {
      const user = this.userService.user$.value;
      if (user) {
        user.isTermsAccepted = true;
        this.tokenStorage.SaveUserToStorage(user);
        this.userService.user$.next(user);
      }
      this.isTermsAccepted = true;
      this.cdr.detectChanges();
    });
  }

  Dispose(index: number) {
    this.currentToasts.splice(index, 1);
    this.cdr.detectChanges();
  }

  DisposeMessage() {
    this.panelmessages = [];
    this.messageDisplay = false;
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.userSessionSubscription.unsubscribe();
  }
}
