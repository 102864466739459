import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'capsule',
  templateUrl: './capsule.component.html',
  styleUrls: ['./capsule.component.scss'],
})
export class CapsuleComponent {
  @Input() label!: string;
  @Input() isBW = false;
  @Output() OnClick = new EventEmitter<string>();

  triggerValue() {
    this.OnClick.emit(this.label);
  }
}
