<ng-container>
  <div class="page-title-container">
    <div *ngIf="alertMessage !== ''" class="mt15">
      <alert-message
        [messageText]="alertMessage"
        [icon]="'check_circle-success.svg'"
        [background]="'alert-success-light success'"
      >
      </alert-message>
    </div>
  </div>
  <div
    *ngIf="pageDisclaimer"
    class="d-flex align-items-start setting-info-container"
  >
    <img src="./assets/icons/info_icon.svg" alt="" />
    <span>{{ pageDisclaimer }}</span>
  </div>
  <div class="row page-container page-content-container mt10">
    <div class="base-container">
      <div class="d-flex justify-content-end">
        <filter
          [isSearchIconVisible]="true"
          (emitFilterData)="ApplyGlobalFilter($event)"
        ></filter>
      </div>
      <div>
        <pagination
          [paginationTitle]="paginationTitle"
          [totalPageNumber]="totalPageNumber"
          [totalRecords]="data.totalCount ?? 0"
          (emitPageConfig)="OnPageChanged($event)"
          [pageFiltered]="pageFiltered"
          (emitClearFilterAction)="ClearFilter($event)"
        >
        </pagination>
      </div>

      <div
        [ngClass]="
          (data.totalCount ?? 0 > 0)
            ? 'grid-table-spacer'
            : 'grid-table-spacer mt-2'
        "
        class="grid-table-spacer"
      >
        <p-table
          #dt
          [lazy]="true"
          styleClass="p-datatable-striped"
          [value]="data.items"
          [customSort]="true"
          sortField="{{ tableProps.orderColumn }}"
          [sortOrder]="tableProps.orderByAscending ? 1 : -1"
          [scrollHeight]="'auto'"
          [globalFilterFields]="globalFilters"
          dataKey="{{ dataKey }}"
          [scrollable]="true"
          class="fw-400"
          (onLazyLoad)="CustomSortandFilter($event)"
          [expandedRowKeys]="expandedRows"
        >
          <ng-template pTemplate="header">
            <tr>
              <th [style.width]="'4%'"></th>
              <th
                [ngStyle]="{ width: gridColumnWidth }"
                *ngFor="let column of tableColumns; let i = index"
                pSortableColumn="{{ column.fieldName }}"
              >
                <div>
                  <div class="d-flex justify-content-start">
                    {{ GetDisplayName(column) }}
                    <div class="align-items-start">
                      <p-sortIcon field="{{ column.fieldName }}"></p-sortIcon>
                      <p-columnFilter
                        *ngIf="column.columnType === columnType.Date"
                        type="date"
                        field="{{ column.fieldName }}"
                        display="menu"
                        [ngClass]="
                          column.selectedDateType!.id !== 0
                            ? 'calender-active ml-auto'
                            : 'ml-auto'
                        "
                        [showClearButton]="false"
                        [showMatchModes]="false"
                        [showApplyButton]="true"
                        [showAddButton]="false"
                        [showOperator]="false"
                        [placeholder]="'Enter Here'"
                        [hideOnClear]="true"
                        id="{{ column.fieldName }}"
                      >
                        <ng-template
                          pTemplate="filter"
                          let-value
                          let-filter="filterCallback"
                        >
                          <p-dropdown
                            [options]="dateObjects"
                            [(ngModel)]="column.selectedDateType"
                            optionLabel="name"
                            styleClass="calendar-icon"
                            dropdownIcon="drop-down-icon"
                            id="dateFilter"
                          ></p-dropdown>
                          <p-calendar
                            *ngIf="column.selectedDateType!.id === 5"
                            [(ngModel)]="column.dateRange"
                            selectionMode="range"
                            [readonlyInput]="true"
                            [showIcon]="true"
                            [styleClass]="'restricted-width mt-1'"
                            placeholder="Enter here"
                          ></p-calendar>
                        </ng-template>
                      </p-columnFilter>
                      <p-columnFilter
                        *ngIf="
                          column.filterOptions &&
                          column.filterOptions.length > 0
                        "
                        field="{{ column.fieldName }}"
                        display="menu"
                        class="ml-auto"
                        [showClearButton]="true"
                        [showApplyButton]="false"
                        [showAddButton]="false"
                        [showOperator]="false"
                        matchMode="equals"
                        [showMatchModes]="false"
                        [hideOnClear]="true"
                        id="{{ column.fieldName }}"
                      >
                        <ng-template
                          pTemplate="filter"
                          let-value
                          let-filter="filterCallback"
                        >
                          <p-dropdown
                            [ngModel]="value"
                            [options]="column.filterOptions"
                            (onChange)="filter($event.value)"
                            placeholder="All"
                          >
                          </p-dropdown>
                        </ng-template>
                      </p-columnFilter>
                      <p-columnFilter
                        *ngIf="
                          column.columnType === columnType.Text &&
                          !column.filterOptions
                        "
                        type="text"
                        field="{{ column.fieldName }}"
                        display="menu"
                        class="ml-auto"
                        [showClearButton]="true"
                        [showApplyButton]="true"
                        [showAddButton]="false"
                        [showOperator]="false"
                        [matchModeOptions]="matchModeOptions"
                        [matchMode]="matchModeOptions[0].value"
                        [placeholder]="'Enter Here'"
                        [hideOnClear]="true"
                        id="{{ column.fieldName }}"
                      >
                      </p-columnFilter>
                    </div>
                  </div>
                </div>
              </th>
              <th [style.width]="'4%'"></th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-b
            let-i="rowIndex"
            let-expanded="expanded"
            sortMode="multiple"
          >
            <tr
              [ngClass]="[
                b.status === InactiveStatus && !expanded
                  ? 'deActivatedRow'
                  : '',
                expanded ? 'expandRow' : '',
              ]"
              gridRowHoverBg
              [id]="i + 1"
            >
              <td
                [ngClass]="b.status === InactiveStatus ? 'deActivatedRow' : ''"
              >
                <button
                  type="button"
                  pButton
                  pRipple
                  [pRowToggler]="b"
                  class="p-button-text p-button-rounded p-button-plain"
                >
                  <img
                    [src]="
                      expanded
                        ? './assets/icons/expand-down.svg'
                        : './assets/icons/expand-right.svg'
                    "
                    alt=""
                  />
                </button>
              </td>
              <td
                *ngFor="let gview of tableColumns"
                [ngClass]="b.status === InactiveStatus ? 'deActivatedRow' : ''"
              >
                {{ GetFieldValue(b, gview) }}
              </td>
              <td
                [ngClass]="b.status === InactiveStatus ? 'deActivatedRow' : ''"
              >
                <app-context-menu
                  (emitPageConfig)="OnPageChanged($event)"
                  [contextMenuItems]="b.contextMenuItems"
                  [align]="'left'"
                  [icon]="expanded ? 'more-vert-lite.svg' : 'more-vert.svg'"
                ></app-context-menu>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-b>
            <tr></tr>
            <tr>
              <td colspan="12" class="expandedbg">
                <div class="expended-row-container">
                  <div
                    *ngFor="let sviewColumn of GetVisibleSnackViewColumns(b)"
                    [ngStyle]="{
                      width: sviewColumn.width ?? gridColumnWidth,
                    }"
                    class="expended-col"
                  >
                    <div class="expanded-col-heading">
                      {{ GetDisplayName(sviewColumn) }}
                    </div>
                    <div class="expanded-col-data">
                      {{ GetFieldValue(b, sviewColumn) }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div *ngIf="data.totalCount === 0" class="no-results text-center">
        No records found
      </div>
    </div>
  </div>
</ng-container>
