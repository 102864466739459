<div class="pr">
  <input
    class="input"
    type="text"
    [(ngModel)]="autocompleteInput"
    #addresstext
    id="addresstext"
    [placeholder]="placeholder"
    [maxlength]="100"
    name="addresstext"
    [readonly]="readonly"
    [disabled]="readonly"
    (input)="CheckInput($event)"
    autocompleteOff
    preventSpace
  />
  <img
    *ngIf="autocompleteInput && !readonly"
    src="./assets/icons/close.svg"
    class="remove-address"
    (click)="RemoveAddress()"
  />
</div>
