<div class="modal-container">
  <div
    class="modal-dialog"
    [style.height.px]="modalConfiguration.height"
    [style.width.px]="modalConfiguration.width"
  >
    <div class="modal-content">
      <div
        class="modal-header d-flex justify-content-between align-items-center"
      >
        <h1 class="fw-500" [innerHtml]="modalConfiguration.header"></h1>
        <img
          src="./assets/icons/close-white.svg"
          alt=""
          *ngIf="modalConfiguration.isCloseable"
          (click)="CloseModalWindow()"
        />
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <ng-container *ngIf="modalConfiguration.button1">
          <button-component
            [buttonClass]="modalConfiguration.button1.buttonClass"
            [Text]="modalConfiguration.button1.text"
            [isLeftImg]="false"
            [isRightImg]="false"
            [isButtonDisabled]="modalConfiguration.button1.disableCallback()"
            (OnClick)="modalConfiguration.button1.callback()"
          ></button-component>
        </ng-container>
        <ng-container *ngIf="modalConfiguration.button2">
          <button-component
            [buttonClass]="modalConfiguration.button2.buttonClass"
            [Text]="modalConfiguration.button2.text"
            [isLeftImg]="false"
            [isRightImg]="false"
            [isButtonDisabled]="false"
            (OnClick)="modalConfiguration.button2.callback()"
          ></button-component>
        </ng-container>
        <ng-container *ngIf="modalConfiguration.includeCancelButton">
          <button-component
            [buttonClass]="'button button-light ml12'"
            [Text]="modalConfiguration.cancelButtonText"
            [isLeftImg]="false"
            [isRightImg]="false"
            [isButtonDisabled]="false"
            (OnClick)="CloseModalWindow()"
          ></button-component>
        </ng-container>
      </div>
    </div>
  </div>
</div>
