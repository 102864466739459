<div class="sidebar-container" (clickOutside)="isSideBarExpanded = false">
  <a
    id="menuNavbar"
    class="pe-2 ps-2 pt-3 menuIcon preventSelect d-inline-flex justify-content-center align-items-center"
    (click)="expandMenu()"
    ><img src="assets\icons\menu.svg"
  /></a>
  <nav
    id="sidebar"
    class="shadow-lg"
    [ngClass]="{ expanded: isSideBarExpanded }"
  >
    <ul class="navbar-nav">
      <li
        class="nav-item"
        #achContactRegistry
        *ngIf="achContactRegistryAccess()"
      >
        <a
          (click)="ShowSubmenu(achContactRegistry)"
          class="menuText fw-500 c-p d-flex align-items-center text-decoration-none"
        >
          <img
            src="assets\icons\groups.svg"
            [ngClass]="{
              active:
                selectedSubmenuIndex === achContactRegistry &&
                isSideBarExpanded === false,
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="ACH Contact Registry"
          />
          <span class="menuTextWidth">ACH Contact Registry</span>
          <img
            *ngIf="isSideBarExpanded"
            class="arrow"
            src="assets\icons\down-arrow.svg"
          />
        </a>
        <a
          [routerLink]="['/ach-contact-registry/contacts']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, achContactRegistry)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >ACH Contact Registry Search</span
          >
        </a>
        <a
          *ngIf="manageContactAccess()"
          [routerLink]="['/ach-contact-registry/create']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, achContactRegistry)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >Manage Contacts/Users</span
          >
        </a>
        <!-- <a
          [routerLink]="['/ach-contact-registry/bulkhistory']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, achContactRegistry)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >ACH Contact Registry Bulk Upload</span
          >
        </a> -->
      </li>

      <li
        class="nav-item"
        #achContactRegistrySearch
        *ngIf="achContactRegistrySearchAccess()"
      >
        <a
          [routerLink]="['/ach-contact-registry/contacts']"
          class="menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, achContactRegistrySearch)"
        >
          <img
            src="assets\icons\groups.svg"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="ACH Contact Registry Search"
            [ngClass]="{
              active: selectedSubmenuIndex === achContactRegistrySearch,
            }"
          />
          <span class="menuTextWidth">ACH Contact Registry Search</span>
        </a>
      </li>

      <li class="nav-item" #thirdpartysender *ngIf="thirdPartySenderAccess()">
        <a
          (click)="ShowSubmenu(thirdpartysender)"
          class="menuText fw-500 c-p d-flex align-items-center text-decoration-none"
        >
          <img
            src="assets\icons\location_away.svg"
            [ngClass]="{
              active:
                selectedSubmenuIndex === thirdpartysender &&
                isSideBarExpanded === false,
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Third-Party Senders"
          />
          <span class="menuTextWidth">Third-Party Senders</span>
          <img
            *ngIf="isSideBarExpanded"
            class="arrow"
            src="assets\icons\down-arrow.svg"
          />
        </a>
        <a
          [routerLink]="['/tps/management']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, thirdpartysender)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >TPS Registration Management</span
          >
        </a>
        <a
          [routerLink]="['/tps/bulk-history']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, thirdpartysender)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >TPS Bulk Upload History</span
          >
        </a>
      </li>

      <li class="nav-item" #directAccess *ngIf="daManagementAccess()">
        <a
          [routerLink]="['/direct-access/management']"
          class="menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, directAccess)"
        >
          <img
            src="assets\icons\dap.svg"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="DA Management"
            [ngClass]="{ active: selectedSubmenuIndex === directAccess }"
          />
          <span class="menuTextWidth">DA Management</span>
        </a>
      </li>

      <li class="nav-item" #tod *ngIf="todAccess()">
        <a
          [routerLink]="['/tod/management']"
          class="menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, tod)"
        >
          <img
            src="assets\icons\tod.svg"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Terminated Originator Database"
            [ngClass]="{ active: selectedSubmenuIndex === tod }"
          />
          <span class="menuTextWidth">Terminated Originator Database</span>
        </a>
      </li>

      <li class="nav-item" #userManagement *ngIf="userManagementAccess()">
        <a
          [routerLink]="['/user-management/list']"
          class="menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, userManagement)"
        >
          <img
            src="assets\icons\users_icon.svg"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="User Management"
            [ngClass]="{ active: selectedSubmenuIndex === userManagement }"
          />
          <span class="menuTextWidth">User Management</span>
        </a>
      </li>

      <li
        class="nav-item"
        #management
        id="management"
        *ngIf="showManagementSection"
      >
        <a
          (click)="ShowSubmenu(management)"
          class="menuText fw-500 c-p d-flex align-items-center text-decoration-none"
        >
          <img
            src="assets\icons\management.svg"
            [ngClass]="{
              active:
                selectedSubmenuIndex === management &&
                isSideBarExpanded === false,
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Management"
          />
          <span class="menuTextWidth">Management</span>
          <img
            *ngIf="isSideBarExpanded"
            class="arrow"
            src="assets\icons\down-arrow.svg"
          />
        </a>
        <a
          *ngIf="paManagementAccess()"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, management)"
          (click)="GotoPAManagement()"
        >
          <span class="menuTextWidth subMenu-items fw-400">PA Management</span>
        </a>
        <a
          *ngIf="achOperatorManagementAccess()"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, management)"
          (click)="GotoAchOperatorManagement()"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >ACH Operator Management</span
          >
        </a>
        <a
          *ngIf="tpspTpsManagementAccess()"
          [routerLink]="['/management/tpsp']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, management)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >TPSP Management</span
          >
        </a>
        <a
          *ngIf="tpspTpsManagementAccess()"
          [routerLink]="['/management/tps']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, management)"
        >
          <span class="menuTextWidth subMenu-items fw-400">TPS Management</span>
        </a>
        <a
          *ngIf="tpspManagementAccess()"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, management)"
          (click)="GotoTpspTpsManagement('TPSP')"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >TPSP Management</span
          >
        </a>
        <a
          *ngIf="tpsManagementAccess()"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, management)"
          (click)="GotoTpspTpsManagement('TPS')"
        >
          <span class="menuTextWidth subMenu-items fw-400">TPS Management</span>
        </a>

        <a
          *ngIf="odfiManagementAccess()"
          [routerLink]="odfiManagementRedirectTo()"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, management)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >ODFI Management</span
          >
        </a>
        <a
          *ngIf="rdfiManagementAccess()"
          [routerLink]="rdfiManagementRedirectTo()"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, management)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >RDFI Management</span
          >
        </a>
        <a
          *ngIf="accuityManagementAccess()"
          [routerLink]="['/accuity-management/data']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, management)"
        >
          <span class="menuTextWidth subMenu-items fw-400">Accuity Data</span>
        </a>
        <a
          *ngIf="accuityManagementAccess()"
          [routerLink]="['/accuity-management/file-upload-history']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, management)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >Accuity File Upload History</span
          >
        </a>
        <a
          *ngIf="reportsManagementAccess()"
          href="#"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="NavigateToPowerBiDashboard($event)"
        >
          <span class="menuTextWidth subMenu-items fw-400">Reports</span>
        </a>
      </li>

      <li
        class="nav-item"
        #secureExchange
        id="secureExchange"
        *ngIf="showSecureExchangeSection()"
      >
        <a
          *ngIf="
            secureTemplateManagementAccess() || secureExchangeAuditLogAccess()
          "
          (click)="ShowSubmenu(secureExchange)"
          class="menuText fw-500 c-p d-flex align-items-center text-decoration-none"
        >
          <img
            src="assets\icons\forward_to_inbox.svg"
            [ngClass]="{
              active:
                selectedSubmenuIndex === secureExchange &&
                isSideBarExpanded === false,
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Secure Exchange"
          />
          <span class="menuTextWidth">Secure Exchange</span>
          <img
            *ngIf="isSideBarExpanded"
            class="arrow"
            src="assets\icons\down-arrow.svg"
          />
        </a>
        <a
          *ngIf="secureTemplateManagementAccess()"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, secureExchange)"
          (click)="GotoSecureTemplateManagement()"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >Template Management</span
          >
        </a>
        <a
          *ngIf="secureExchangeAuditLogAccess()"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, secureExchange)"
          (click)="GotoSecureExchangeAuditLogs()"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >Exchange Activity</span
          >
        </a>
        <a
          *ngIf="secureDocumentAccess()"
          (click)="GotoSecureDocument()"
          (click)="menuFocus($event, secureExchange)"
          class="menuText fw-500 c-p d-flex align-items-center text-decoration-none"
        >
          <img
            src="assets\icons\forward_to_inbox.svg"
            [ngClass]="{ active: selectedSubmenuIndex === secureExchange }"
            title="Secure Exchange"
          />
          <span class="menuTextWidth">Secure Exchange</span>
        </a>
      </li>

      <li
        class="nav-item"
        #incidentSharingBoard
        *ngIf="incidentSharingBoardAccess()"
      >
        <a
          [routerLink]="'/incident-sharing-board'"
          class="menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, incidentSharingBoard)"
        >
          <img
            [src]="isbIcon"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Incident Sharing Board"
            [ngClass]="{
              active: selectedSubmenuIndex === incidentSharingBoard,
            }"
          />
          <span class="menuTextWidth">Incident Sharing Board</span>
        </a>
      </li>

      <li class="nav-item" #pendingApprovals *ngIf="pendingApprovalsAccess()">
        <a
          (click)="ShowSubmenu(pendingApprovals)"
          class="menuText fw-500 c-p d-flex align-items-center text-decoration-none"
        >
          <img
            src="assets\icons\pending-actions.svg"
            [ngClass]="{
              active:
                selectedSubmenuIndex === pendingApprovals &&
                isSideBarExpanded === false,
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Pending Approvals"
          />
          <span class="menuTextWidth">Pending Approvals</span>
          <img
            *ngIf="isSideBarExpanded"
            class="arrow"
            src="assets\icons\down-arrow.svg"
          />
        </a>
        <a
          [routerLink]="['/ach-contact-registry/pending-approvals']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, pendingApprovals)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >Pending ACH Contact Approvals</span
          >
        </a>
        <!-- Not visible for nacha user -->
        <a
          *ngIf="!isNachaUser"
          [routerLink]="['/pending-approvals/pending-other-approvals']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, pendingApprovals)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >Pending Other Approvals</span
          >
        </a>
      </li>

      <li
        class="nav-item"
        #registrationSummary
        *ngIf="registrationSummaryAccess()"
      >
        <a
          (click)="ShowSubmenu(registrationSummary)"
          class="menuText fw-500 c-p d-flex align-items-center text-decoration-none"
        >
          <img
            src="assets\icons\regsummary.svg"
            [ngClass]="{
              active:
                selectedSubmenuIndex === registrationSummary &&
                isSideBarExpanded === false,
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Registration Summary"
          />
          <span class="menuTextWidth">Registration Summary</span>
          <img
            *ngIf="isSideBarExpanded"
            class="arrow"
            src="assets\icons\down-arrow.svg"
          />
        </a>
        <a
          [routerLink]="['/registration-summary/details']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, registrationSummary)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >Registration Summary Document</span
          >
        </a>
        <a
          [routerLink]="['/registration-summary/template']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, registrationSummary)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >Registration Summary Template</span
          >
        </a>
      </li>

      <li
        class="nav-item"
        #registrationSummaryDocument
        *ngIf="registrationSummaryDocumentAccess()"
      >
        <a
          [routerLink]="['/registration-summary/details']"
          class="menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, registrationSummaryDocument)"
        >
          <img
            src="assets\icons\regsummary.svg"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Registration Summary Document"
            [ngClass]="{
              active: selectedSubmenuIndex === registrationSummaryDocument,
            }"
          />
          <span class="menuTextWidth">Registration Summary Document</span>
        </a>
      </li>

      <li class="nav-item" #auditLog *ngIf="auditLogAccess()">
        <a
          [routerLink]="['/audit-logs/logs']"
          class="menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, auditLog)"
        >
          <img
            src="assets\icons\auditlog.svg"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Audit Log"
            [ngClass]="{ active: selectedSubmenuIndex === auditLog }"
          />
          <span class="menuTextWidth">Audit Log</span>
        </a>
      </li>

      <li class="nav-item" #optInProgram *ngIf="optInProgramAccess()">
        <a
          (click)="ShowSubmenu(optInProgram)"
          class="menuText fw-500 c-p d-flex align-items-center text-decoration-none"
        >
          <img
            src="assets\icons\opt-in.svg"
            [ngClass]="{
              active:
                selectedSubmenuIndex === optInProgram &&
                isSideBarExpanded === false,
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Opt-In Program"
          />
          <span class="menuTextWidth">Opt-In Program</span>
          <img
            *ngIf="isSideBarExpanded"
            class="arrow"
            src="assets\icons\down-arrow.svg"
          />
        </a>
        <a
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none c-p"
          (click)="DownloadOptInDocumentFile()"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >Opt-In Program List</span
          >
        </a>
        <a
          *ngIf="OptInProgramUploadAccess()"
          [routerLink]="['/management/opt-in-upload']"
          class="sub-menu menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, optInProgram)"
        >
          <span class="menuTextWidth subMenu-items fw-400"
            >Opt-In Program Upload</span
          >
        </a>
      </li>

      <li class="nav-item" *ngIf="optInProgramListAccess()">
        <a
          class="menuText fw-500 d-flex align-items-center text-decoration-none c-p"
          (click)="DownloadOptInDocumentFile()"
        >
          <img
            src="assets\icons\opt-in.svg"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Opt-In Program List"
          />
          <span class="menuTextWidth">Opt-In Program List</span>
        </a>
      </li>

      <li
        class="nav-item"
        #suspendedOriginator
        *ngIf="suspendedOriginatorAccess()"
      >
        <a
          [routerLink]="['/suspended-originator']"
          class="menuText fw-500 d-flex align-items-center text-decoration-none"
          (click)="menuFocus($event, suspendedOriginator)"
        >
          <img
            src="assets\icons\suspendedorg_tps.svg"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Suspended Originator/TPS"
            [ngClass]="{ active: selectedSubmenuIndex === suspendedOriginator }"
          />
          <span class="menuTextWidth">Suspended Originator/TPS</span>
        </a>
      </li>

      <li class="nav-item" #portalSettings *ngIf="portalSettingsAccess()">
        <a
          class="menuText fw-500 d-flex align-items-center text-decoration-none c-p"
          (click)="GotoAdministrationSettingsPage()"
          (click)="menuFocus($event, portalSettings)"
        >
          <img
            src="assets\icons\settings-menu.svg"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            title="Portal Administrative Settings"
            [ngClass]="{ active: selectedSubmenuIndex === portalSettings }"
          />
          <span class="menuTextWidth">Portal Administrative Settings</span>
        </a>
      </li>
    </ul>
  </nav>
</div>
