import { Component, Input } from '@angular/core';
import { WizardStepDto } from '../../../models/WizardStepDto';

@Component({
  selector: 'wizard-common',
  templateUrl: './wizard-common.component.html',
  styleUrls: ['./wizard-common.component.scss'],
})
export class WizardCommonComponent {
  @Input() wizard: WizardStepDto[] = [];
  @Input() activeStep = 1;
  @Input() isAllStepValid = false;
  @Input() pageTitle = '';
  @Input() ClickPrev!: () => void;
  @Input() ClickNext!: () => void;
  @Input() autoSaveMessage = '';

  ngOnChange(changes: any) {
    this.activeStep = changes.activeStep?.currentValue;
    this.isAllStepValid = changes.isAllStepValid?.currentValue;
  }
}
