import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { GeneralService } from 'src/app/services/general.service';
import { TimerService } from '../services/timer.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  private sessionTimeInSec = 900; // Placeholder initial value
  private isFetchingSessionTime = false;

  constructor(
    private authService: AuthenticationService,
    private generalService: GeneralService,
    private timerService: TimerService,
  ) {}

  // Expose sessionTimeRemaining$ as observable
  public get sessionTimeRemaining$(): Observable<number> {
    return this.timerService.GetTimerObservable('session');
  }

  public StartSessionCountdown(): void {
    if (this.sessionTimeInSec === 999999) {
      if (this.isFetchingSessionTime) {
        return;
      }
      this.isFetchingSessionTime = true;
      this.generalService.GetTimeoutSessionValue().then((time) => {
        this.sessionTimeInSec = time;
        this.StartTimer(this.sessionTimeInSec);
        this.isFetchingSessionTime = false;
      });
    } else {
      this.StartTimer(this.sessionTimeInSec);
    }
  }

  private StartTimer(duration: number) {
    this.timerService.StartTimer('session', {
      durationInSeconds: duration,
      resetOnMouseMove: true,
    });
  }

  public Logout() {
    this.timerService.StopTimer('session');
    this.authService.ExternalLogout();
  }

  public ExtendSession() {
    this.StartSessionCountdown();
  }
}
