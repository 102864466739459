<div class="tab-container">
  <ng-container *ngFor="let t of tabs; let i = index">
    <a
      [routerLink]="t.route"
      [routerLinkActive]="['activeTab']"
      class="tab-item"
      (click)="SelectTab(t)"
      >{{ t.name }}</a
    >
  </ng-container>
</div>
