import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TabsDto } from 'src/app/models/TabsDto';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent {
  @Input() tabs: TabsDto[] = [];
  @Output() Click = new EventEmitter<unknown>();

  SelectTab(tab: TabsDto) {
    this.Click.emit(tab);
  }
}
