<div class="wizard-container">
  <div class="step-container-wrapper d-flex align-items-center">
    <div
      class="step-item-container d-flex align-items-center"
      *ngFor="let w of wizard"
      (click)="isEditMode ? onStepClick(w.step) : null"
      [ngClass]="{
        'c-p': w.step !== activeStep && isEditMode,
        'step-right-line': w.step < wizard.length,
      }"
    >
      <div
        class="item-data-container d-flex"
        [ngClass]="w.step === activeStep ? 'activeStep' : ''"
      >
        <div
          class="item-check-container"
          [ngClass]="
            isEditMode
              ? isAllStepValid
                ? 'greenBg'
                : w.step === activeStep
                  ? 'blueBg'
                  : 'grayBg'
              : w.step < activeStep
                ? 'greenBg'
                : w.step === activeStep
                  ? 'blueBg'
                  : 'grayBg'
          "
        >
          <!-- Edit Mode -->
          <ng-container *ngIf="isEditMode">
            <span
              *ngIf="!isAllStepValid && w.step === activeStep"
              class="whiteColor"
            >
              {{ w.step }}
            </span>
            <img
              *ngIf="isAllStepValid || w.step !== activeStep"
              src="./assets/icons/check_circle-success.svg"
              alt=""
            />
          </ng-container>

          <!-- Create Mode -->
          <ng-container *ngIf="!isEditMode">
            <span
              *ngIf="w.step >= activeStep"
              [ngClass]="w.step === activeStep ? 'whiteColor' : 'darkGrayColor'"
            >
              {{ w.step }}
            </span>
            <img
              *ngIf="w.step < activeStep"
              src="./assets/icons/check_circle-success.svg"
              alt=""
            />
          </ng-container>
        </div>
        <div
          class="item-text-container"
          [ngClass]="
            activeStep === w.step
              ? 'darkColor'
              : isAllStepValid
                ? 'blueColor'
                : activeStep > w.step
                  ? 'blueColor'
                  : 'darkColor'
          "
          [innerHTML]="w.name"
        ></div>
      </div>
      <div class="line-between-steps" *ngIf="w.step < wizard.length"></div>
    </div>
  </div>
</div>
