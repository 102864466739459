import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  private appInsights: ApplicationInsights | null = null;
  private appInsightsInitialized: Promise<void>;

  constructor(private generalService: GeneralService) {
    this.appInsightsInitialized = new Promise((resolve) => {
      this.generalService.msalConfigs$.subscribe((data) => {
        const msalConfigs: any = data.appInsightsConfigKey
          .split(';')
          .reduce((acc, item) => {
            const keyValue = item.split('=');
            return { ...acc, [keyValue[0]]: keyValue[1] };
          }, {});

        this.appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: msalConfigs['InstrumentationKey'],
            enableAutoRouteTracking: true,
          },
        });

        this.appInsights.loadAppInsights();
        resolve();
      });
    });
  }

  private async ensureInitialized() {
    await this.appInsightsInitialized;
  }

  async logPageView(name?: string, url?: string) {
    await this.ensureInitialized();
    this.appInsights?.trackPageView({ name, uri: url });
  }

  async logEvent(name: string, properties?: { [key: string]: any }) {
    await this.ensureInitialized();
    this.appInsights?.trackEvent({ name }, properties);
  }

  async logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any },
  ) {
    await this.ensureInitialized();
    this.appInsights?.trackMetric({ name, average }, properties);
  }

  async logException(exception: Error, severityLevel?: number) {
    await this.ensureInitialized();
    this.appInsights?.trackException({ exception, severityLevel });
  }

  async logTrace(message: string, properties?: { [key: string]: any }) {
    await this.ensureInitialized();
    this.appInsights?.trackTrace({ message }, properties);
  }
}
