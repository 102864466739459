import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {
  transform(value: Date): string {
    // TAKE DATE
    // if time is less than 60 seconds, return 'Just now',
    // if less then 60 minutes, return 'x minutes ago',
    // if less than 24 hours, return 'x hours ago',
    // else return 'date'
    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
    if (seconds < 60) {
      return 'Just now';
    }
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return minutes < 2 ? minutes + ' min ago' : minutes + ' mins ago';
    }
    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return hours < 2 ? hours + ' hr ago' : hours + ' hrs ago';
    }
    return moment(value).format('MM/DD/YYYY');
  }
}
