import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationSummaryRoutingModule } from './routing/registration-summary-routing.module';
import { SharedModule } from '../shared/shared.module';
import { RegistrationSummaryComponent } from './registration-summary.component';
import {
  RegistrationSummaryService,
  SummaryDetails,
} from './services/registration-summary.service';
import { RegistrationDetailsComponent } from './registration-details/registration-details.component';
import { RegistrationTemplateComponent } from './registration-template/registration-template.component';
import { CommonComponentsModule } from '../common-components/common-components.module';

@NgModule({
  declarations: [
    RegistrationSummaryComponent,
    RegistrationDetailsComponent,
    RegistrationTemplateComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RegistrationSummaryRoutingModule,
    CommonComponentsModule,
  ],
  providers: [RegistrationSummaryService, SummaryDetails],
})
export class RegistrationSummaryModule {}
