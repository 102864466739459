import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Constants } from 'src/app/models/Constants';

@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent {
  @Input() public pageTitle = '';
  @Input() public createButtonVisible = false;
  @Input() public createButtonLabel = 'Manual Entry';
  @Input() public exportButtonVisible = false;
  @Input() public exportDataLength = 0;
  @Input() public hasProjectedButtons = false;

  @Output() emitExportType = new EventEmitter<string>();
  @Output() emitCreateButtonAction = new EventEmitter<any>();

  defaultExportKey = Constants.ExcelExportKey;

  public exportItems = [
    {
      label: 'Excel',
      command: () => {
        this.OnClickExportButtonAction(Constants.ExcelExportKey);
      },
    },
    {
      label: 'CSV',
      command: () => {
        this.OnClickExportButtonAction(Constants.CsvExportKey);
      },
    },
  ];

  OnClickCreateButtonAction() {
    this.emitCreateButtonAction.emit();
  }

  OnClickExportButtonAction(exportType: string) {
    this.emitExportType.emit(exportType);
  }
}
