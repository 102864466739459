import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TableLazyLoadEvent } from 'primeng/table';
import {
  ItemsCollectionDto,
  TableColumnConfigDto,
  TableEventArgs,
  TableFilterDto,
} from 'src/app/models/TableDtos';
import { FilterMatchMode } from 'primeng/api';
import { DateService } from 'src/app/services/date.service';
import { DatePick } from 'src/app/models/DatePickDto';
import { TableColumnType } from 'src/app/models/TableDtos';
import { resetPagination$ } from '../pagination/pagination.component';
import { omit, set, startCase } from 'lodash';
import { LocalDatePipe } from 'src/app/pipes/local-date-pipe';
@Component({
  selector: 'rmp-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() set columns(value: TableColumnConfigDto[]) {
    this.tableColumns = value.filter((x) => !x.snackView);
    this.tableColumns.forEach((x) => {
      x.filterOptions = this.GetFilterOptions(x);
      if (x.columnType === TableColumnType.Date) {
        x.selectedDateType = { id: 0, name: 'All' };
      }

      if (x.defaultSort) {
        this.tableProps.orderColumn = x.fieldName;
        this.tableProps.orderByAscending = x.ascending ?? false;
      }
    });
    this.snackViewColumns = value.filter((x) => x.snackView);
  }
  @Input() set tableData(value: ItemsCollectionDto<any>) {
    this.data = value;
    this.totalPageNumber = this.data.totalCount
      ? Math.ceil(this.data.totalCount / this.tableProps.pageSize)
      : 1;
  }
  @Input() public dataKey: string = 'id';
  @Input() public showPaginator = true;
  @Input() public globalFilters: string[] | undefined;
  @Input() public pageDisclaimer: string = '';
  @Input() public alertMessage: string = '';
  @Output() public updateTableData = new EventEmitter<TableEventArgs>();

  paginationTitle = 'Records per page';
  totalPageNumber = 1;
  tableColumns: TableColumnConfigDto[] = [];
  snackViewColumns: TableColumnConfigDto[] = [];
  data: ItemsCollectionDto<any> = { items: [] };
  columnType = TableColumnType;
  expandedRows: { [s: string]: boolean } = {};
  gridColumnWidth = '15%';
  matchModeOptions: { label: string; value: any }[];
  dateObjects: DatePick[];
  pageFiltered: boolean = false;
  InactiveStatus = 'Inactive';
  ActiveStatus = 'Active';
  tableProps: TableEventArgs = {
    currentPage: 1,
    pageSize: 10,
    orderColumn: '',
    orderByAscending: true,
    skip: 0,
    globalFilters: [],
  };
  /**
   *
   */
  constructor(
    private readonly dateService: DateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly localDatePipe: LocalDatePipe,
  ) {
    this.matchModeOptions = [
      { label: 'Contains', value: FilterMatchMode.CONTAINS },
      { label: 'Equals', value: FilterMatchMode.EQUALS },
      { label: 'Starts', value: FilterMatchMode.STARTS_WITH },
      { label: 'Ends', value: FilterMatchMode.ENDS_WITH },
    ];
    this.dateObjects = this.dateService.GetDateDropdownOptions();
  }

  ngOnInit() {
    // Ensure initial values are set correctly
    this.cdr.detectChanges();
  }

  async CustomSortandFilter(event: TableLazyLoadEvent) {
    const sortColumn = event.sortField as string;
    this.sortOrderColumn(sortColumn, event.sortOrder!);
    this.resetPagination();
    if (event.filters) {
      Object.keys(event.filters).forEach((x: string) => {
        this.tableProps = omit(this.tableProps, x) as TableEventArgs; // clear previous filter on the column
        if (!event.filters) return;
        const filter = event.filters[x] as TableFilterDto[];
        const columnConfig = this.tableColumns.find((y) => y.fieldName === x);
        if (!columnConfig) return;
        if (filter[0].value !== null && filter[0].value !== 'All') {
          set(this.tableProps, x, {
            filterValue: filter[0].value,
            filterMatchMode: filter[0].matchMode,
          });
        }
        if (
          columnConfig.columnType === TableColumnType.Date &&
          columnConfig.selectedDateType
        ) {
          set(
            this.tableProps,
            x,
            this.dateService.ConstructDateFilter(
              columnConfig.selectedDateType,
              columnConfig.dateRange ?? undefined,
            ),
          );
        }
      });
    }
    await this.updateTableData.emit(this.tableProps);
    this.cdr.detectChanges();
  }
  GetFilterOptions(column: TableColumnConfigDto) {
    switch (column.columnType) {
      case TableColumnType.Status:
        return [
          { label: 'All', value: 'All' },
          { label: this.ActiveStatus, value: this.ActiveStatus },
          { label: this.InactiveStatus, value: this.InactiveStatus },
        ];
      case TableColumnType.Boolean:
        return [
          { label: 'All', value: 'All' },
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' },
        ];
      default:
        return column.filterOptions;
    }
  }
  sortOrderColumn(sortField: string, sortOrder: number) {
    this.tableProps.orderColumn = sortField;
    this.tableProps.orderByAscending =
      sortField && sortOrder == 1 ? true : false;
  }
  ClearFilter(_t20: HTMLElement) {
    this.resetPagination();
    this.updateTableData.emit(this.tableProps);
  }
  async OnPageChanged(event: any) {
    this.tableProps.currentPage = event.pageNumber;
    this.tableProps.pageSize = event.activePageSize;
    this.tableProps.skip = (event.pageNumber - 1) * event.activePageSize;
    await this.updateTableData.emit(this.tableProps);
  }
  GetVisibleSnackViewColumns(row: any) {
    return this.snackViewColumns.filter(
      (x) => !x.IsColumnHidden || !x.IsColumnHidden(row),
    );
  }
  GetDisplayName(column: TableColumnConfigDto) {
    return column.displayName || startCase(column.fieldName);
  }
  GetFieldValue(row: any, columnConfig: TableColumnConfigDto) {
    const displayData = columnConfig.getFieldValue
      ? columnConfig.getFieldValue(row)
      : columnConfig.columnType === this.columnType.Date
        ? this.localDatePipe.transform(row[columnConfig.fieldName])
        : columnConfig.columnType === this.columnType.Status
          ? row[columnConfig.fieldName] === this.ActiveStatus
            ? this.ActiveStatus
            : 'InActive'
          : row[columnConfig.fieldName];
    return displayData;
  }
  resetPagination() {
    resetPagination$.next(true);
    this.totalPageNumber = 1;
    this.tableProps.currentPage = 1;
    this.tableProps.skip = 0;
  }

  async ApplyGlobalFilter($event: any) {
    await this.CustomSortandFilter($event);
  }
}
