export const ContactStatus = {
  active: 'Active',
  inactive: 'Inactive',
  rejected: 'Rejected',
  pending: 'Pending Approval',
};

export const ContactType = {
  department: 'Department',
  individual: 'Individual',
  primary: 'Primary',
  secondary: 'Secondary',
  mandatory: 'mandatory',
  optional: 'optional',
};

export const ContactAction = {
  deactivate: 'Deactivate',
  activate: 'Activate',
};

export const ValidationRegex = {
  routing: '^(?!0+$)[0-9]*$',
  number: '^[0-9]*$',
  zipCode: '^[a-zA-Z0-9- ]*$',
  email: /^[a-zA-Z0-9]+(?:[._%+-]*[a-zA-Z0-9])*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  phoneNumber: '^\\d{3}-\\d{3}-\\d{4}$',
  taxId: '^\\d{2}-\\d{7}$',
  specialCharacters: '^[a-zA-Z0-9-,.@ ]*$',
  firstLastNamePattern: '/^[a-zA-Z]+$/',
};

export const wizardSteps = {
  stepZero: 0,
  stepOne: 1,
  stepTwo: 2,
  stepThree: 3,
};

export const SecureExchange = {
  defaultRetentionConfig: 60,
};
