import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[scroll]',
})
export class ScrollDirective {
  // this directive is used to detect the scroll event and emit the event to the parent component when scroll reach to the bottom of container

  @Output() ScrollToBottom = new EventEmitter<boolean>();
  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 50
    ) {
      this.ScrollToBottom.emit(true);
    } else {
      this.ScrollToBottom.emit(false);
    }
  }
}
