<div class="row wizard-list-container wizard-stepwrapper">
  <div class="col-md-7">
    <div class="wizard-header-container d-flex align-itens-center">
      <h1 class="fw-300">
        {{ pageTitle }}
      </h1>
    </div>
  </div>
  <div class="col-md-5 d-flex justify-content-end">
    <div class="wizard-action-container d-flex align-items-center">
      <div class="wizard-left-actions d-flex">
        <div class="action-btn-container" *ngIf="activeStep > 1">
          <button-component
            [buttonClass]="'button button-dark btnImgLeft'"
            [isLeftImg]="true"
            [isRightImg]="false"
            [buttonImage]="'single-arrow-left-light.png'"
            Text="Previous"
            [isButtonDisabled]="false"
            (OnClick)="ClickPrev()"
          ></button-component>
        </div>
        <div
          class="action-btn-container"
          *ngIf="activeStep < wizard.length"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="{{
            !wizard[activeStep - 1].valid ? 'Enter all required fields' : ''
          }}"
        >
          <button-component
            [buttonClass]="
              wizard[activeStep - 1].valid
                ? 'button button-dark btnImgRight flex-row-reverse'
                : 'button button button-gray flex-row-reverse'
            "
            [isLeftImg]="false"
            [isRightImg]="true"
            [buttonImage]="
              wizard[activeStep - 1].valid
                ? 'single-arrow-right-light.png'
                : 'single-arrow-right-dark.png'
            "
            Text="Next"
            [isButtonDisabled]="!wizard[activeStep - 1].valid"
            (OnClick)="ClickNext()"
          ></button-component>
        </div>
        <div class="img-seperator">
          <img src="./assets/icons/pipe-line.png" alt="" />
        </div>
        <div class="wizard-left-actions d-flex">
          <ng-content select="[buttons]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="edit-info pt-spacer wizard-list-container d-flex align-items-center"
  *ngIf="autoSaveMessage"
>
  <img [src]="'./assets/icons/warning.svg'" alt="" />
  <span class="pl-15">{{ autoSaveMessage }}</span>
</div>

<div class="row pt-spacer wizard-list-container">
  <div class="col-9">
    <div class="wizard-container">
      <div class="step-container-wrapper d-flex align-items-center">
        <div
          class="step-item-container d-flex align-items-center"
          [ngClass]="w.step < wizard.length ? 'step-right-line' : ''"
          *ngFor="let w of wizard"
        >
          <div
            class="item-data-container d-flex align-items-center"
            [ngClass]="w.step === activeStep ? 'activeStep' : ''"
          >
            <div
              class="item-check-container"
              [ngClass]="
                w.step < activeStep
                  ? 'greenBg'
                  : w.step === activeStep
                    ? 'blueBg'
                    : 'grayBg'
              "
            >
              <span
                *ngIf="w.step >= activeStep"
                [ngClass]="
                  w.step === activeStep ? 'whiteColor' : 'darkGrayColor'
                "
                >{{ w.step }}</span
              >
              <img
                *ngIf="w.step < activeStep"
                src="./assets/icons/check_circle-success.svg"
                alt=""
              />
            </div>
            <div
              class="item-text-container"
              [ngClass]="
                activeStep !== w.step
                  ? 'darkColor'
                  : isAllStepValid
                    ? 'blueColor'
                    : activeStep > w.step
                      ? 'blueColor'
                      : 'darkColor'
              "
              [innerHTML]="w.name"
            ></div>
          </div>
          <div class="line-between-steps" *ngIf="w.step < wizard.length"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <ng-content select="[info]"></ng-content>
  </div>
</div>
<div class="row wizard-compo-container">
  <ng-content select="[steps]"></ng-content>
</div>
