import { Component, EventEmitter, Input, Output } from '@angular/core';
import { todWizardDto } from '../../tod/models/todWizardDto';

@Component({
  selector: 'tod-wizard',
  templateUrl: './tod-wizard.component.html',
  styleUrls: ['./tod-wizard.component.scss'],
})
export class TodWizardComponent {
  @Input() wizard: todWizardDto[] = [];
  @Input() activeStep = 1;
  @Input() isAllStepValid = false;
  @Input() isEditMode = false;
  @Output() stepChanged: EventEmitter<number> = new EventEmitter<number>();

  ngOnChange(changes: any) {
    this.activeStep = changes.activeStep?.currentValue;
    this.isAllStepValid = changes.isAllStepValid?.currentValue;
  }

  onStepClick(step: number) {
    if (this.isAllStepValid && (step === 1 || step === 2 || step === 3)) {
      this.activeStep = step;
      this.stepChanged.emit(this.activeStep);
    }
  }
}
