import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/infrastructure/authentication/authentication.service';
import { PortalRegistrationService } from '../../portal-registration/services/portal-registration.service';
import { SpinnerService } from '../../common-components/spinner/service/spinner.service';
import { LoginLink } from '../models/login-link';
import { GeneralService } from 'src/app/services/general.service';
import { FAQSectionDto } from '../../faq/models/FAQSectionDto';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isLoggedInProgress = false;
  PortalRegRegisterMessage!: string;
  isScheduleMaintenance = false;
  fiTypeList = [
    {
      id: 1,
      text: 'Originating Depository Financial Institution (ODFI)',
      type: 'ODFI',
      tooltip:
        'Are you an FI sending ACH files into the Network, either to the Operator or your service provider?',
    },
    {
      id: 2,
      text: 'Receiving Depository Financial Institution (RDFI)',
      type: 'RDFI',
      tooltip:
        'Are you an FI only receiving ACH files, either from the ACH Operator or your service provider?',
    },
    {
      id: 3,
      text: 'Third-Party Sender (TPS)',
      type: 'TPS',
      tooltip:
        'Are you the intermediary between an Originator and an ODFI, and sending ACH entries on behalf of that Originator?',
    },
    {
      id: 4,
      text: 'Third-Party Service Provider (TPSP)',
      type: 'TPSP',
      tooltip:
        'Do you perform ACH-related processing functions on behalf of an Originator, Third-Party Sender, ODFI, or RDFI?',
    },
  ];
  faqList: LoginLink[] = [];
  subscriptions: Subscription = new Subscription();
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private portalRegistrationService: PortalRegistrationService,
    private spinner: SpinnerService,
    private readonly generalService: GeneralService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    // indicates that the user clicked cancel or something went wrong
    if (this.router.url.includes('error=access_denied')) {
      this.authService.NavigateToLogin();
    }
    // indicates that the user is logging in successfully
    else if (this.router.url.includes('redirect#')) {
      this.ShowLoginInProgress();
    }
    this.subscriptions.add(
      this.portalRegistrationService.portalRegisterMessage.subscribe(
        (res: string) => {
          if (res) {
            this.PortalRegRegisterMessage = res;
          }
        },
      ),
    );
    this.generalService.GetFAQsAsync().then((data: FAQSectionDto[]) => {
      this.faqList = data.map((section) => ({
        text: section.title,
        url: '/faq',
        faqSectionHtmlId: section.htmlId,
      }));
      this.cdr.detectChanges();
    });
  }

  Login = () => {
    this.authService.Login();
  };

  Logout = () => {
    this.authService.ExternalLogout();
  };

  PortalRegistration(fiType: string) {
    this.router.navigate(['/portal-registration'], {
      queryParams: { fiType: fiType },
    });
  }

  ShowLoginInProgress() {
    this.isLoggedInProgress = true;
    this.spinner.Show();
  }

  GoToPortalFAQs(url: LoginLink) {
    const extras = {
      id: url.faqSectionHtmlId,
    };
    const generatedUrl = this.router
      .createUrlTree([url.url], { queryParams: extras })
      .toString();
    window.open(generatedUrl, '_blank');
  }

  ngOnDestroy() {
    this.portalRegistrationService.portalRegisterMessage.next('');
    this.subscriptions.unsubscribe();
  }
}
