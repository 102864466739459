import { DatePick } from './DatePickDto';

export interface TableColumnConfigDto {
  fieldName: string;
  displayName?: string;
  isSortable?: boolean;
  isFilterable?: boolean;
  columnType: TableColumnType;
  width?: string;
  dateRange?: Date[];
  selectedDateType?: DatePick;
  customDate?: boolean;
  filterOptions?: any[];
  selectedFilterOption?: any;
  snackView?: boolean;
  defaultSort?: boolean;
  ascending?: boolean;
  getFieldValue?: (item: any) => any;
  IsColumnHidden?: (item: any) => boolean;
}
export enum TableColumnType {
  Text,
  Date,
  Boolean,
  Status,
  Icon,
}
export interface TableDataSortDto {
  orderByAscending: boolean;
  orderColumn: string;
}

export interface ItemsCollectionDto<T> {
  items: T[];
  totalCount?: number;
}

export interface TableFilterDto {
  /**
   * The value used for filtering.
   */
  value?: any;
  /**
   * The match mode for filtering.
   */
  matchMode?: string;
  /**
   * The operator for filtering.
   */
  operator?: string;
}
export interface TableEventArgs {
  currentPage: number;
  pageSize: number;
  orderColumn: string;
  orderByAscending: boolean;
  skip: number;
  globalFilters: string[] | undefined;
}
