export class Constants {
  public static readonly DateRangePlaceholderText = 'mm/dd/yyyy - mm/dd/yyyy';
  public static readonly TaxIdRegex = new RegExp(/^\d{2}-\d{7}$/);
  public static readonly ExcelExportKey = 'xlsx';
  public static readonly CsvExportKey = 'csv';
  public static readonly DocStatusOpen = 'opened';
  public static readonly DocRequestOpened = 'request opened';
  public static readonly DocReplyOpened = 'reply opened';
  public static readonly DocReplySent = 'reply sent';
  public static readonly DocRequestSent = 'request sent';
  public static readonly DocStatusReplyInProgress = 'reply in progress';
  public static readonly DocRequestDownloadBySender =
    'request downloaded by sender';
  public static readonly DocRequestDownloadByRecipient =
    'request downloaded by recipient';
  public static readonly DocStatusReplyDownloadBySender =
    'reply downloaded by sender';
  public static readonly DocStatusReplyDownloadByRecipient =
    'reply downloaded by recipient';
  public static readonly DocStatusDownloadedByRecipient =
    'downloaded by recipient';
  public static readonly DocStatusDownloadedBySender = 'downloaded by sender';
  public static readonly IsReAuthenticatedClaim = 'isReAuthenticated';
  public static readonly NameAndId = 'NameAndId';
  public static readonly Name = 'Name';
  public static readonly Id = 'Id';
}

export class TextKeyConstants {
  public static readonly SEFooterInfo = 'SecureExchange_FooterInfo';
  public static readonly SEPreviewInfo = 'SecureExchange_PreviewInfo';
  public static readonly SEAutoSaveInfo = 'SecureExchange_AutoSaveInfo';
}
