<div class="filter-container" (clickOutside)="isFilterActive = false">
  <button
    class="button button-light"
    [ngClass]="{ 'filter-active': isFilterActive }"
    (click)="isFilterActive = !isFilterActive"
  >
    Filter
    <img [src]="'./assets/icons/expand_more.svg'" />
  </button>
  <div class="filter-main-box" *ngIf="isFilterActive">
    <div class="d-flex justify-content-between align-items-center">
      <span class="filter-header fw-700">Filters </span>
      <div class="d-flex">
        <button
          id="clearAll"
          class="button button-light"
          (click)="ClearFilter()"
        >
          Clear
        </button>
        <img
          class="filter-close-button c-p"
          src="./assets/icons/close-filter.svg"
          alt=""
          (click)="isFilterActive = false"
        />
      </div>
    </div>
    <div class="filter-line-wrapper">
      <horizontal-line></horizontal-line>
    </div>
    <div
      class="filter-wrapper"
      *ngFor="let f of _filterList; let index = index"
    >
      <div *ngIf="f.filterMode === 'checkbox'">
        <div class="filter-categories-header fw-700">{{ f.filterType }}</div>
        <div class="filter-list-box" *ngIf="f.isSelectAll">
          <label class="checkbox-container fw-400"
            >Show All
            <input
              type="checkbox"
              [value]="'Select All'"
              [checked]="f.allChecked"
              (change)="SelectAllCheckbox($event, index)"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div
          class="filter-cat-box d-flex flex-wrap"
          [ngClass]="SetCheckboxDirection(f.filters)"
        >
          <div
            class="filter-list-box"
            *ngFor="let fc of f.filters; let i = index"
          >
            <div *ngIf="fc.name === 'All'">
              <label class="checkbox-container fw-400"
                >{{ fc.name }}
                <span class="filter-count fw-400" *ngIf="fc.count"
                  >({{ fc.count }})</span
                >
                <input
                  type="checkbox"
                  id="checkbox"
                  [value]="fc.name"
                  [checked]="f.allChecked"
                  (change)="SelectAllCheckbox($event, index)"
                />
                <span class="checkmark"></span>
              </label>
            </div>
            <div *ngIf="fc.name !== 'All'">
              <label class="checkbox-container fw-400"
                >{{ fc.name === 'NA' ? 'Not Assigned' : fc.name }}
                <span class="filter-count fw-400" *ngIf="fc.count"
                  >({{ fc.count }})</span
                >
                <input
                  type="checkbox"
                  id="checkbox"
                  [value]="fc.name"
                  [checked]="fc.checked"
                  (change)="SelectFilterCheckbox($event, index, i)"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="f.filterMode === 'primeDropBox'">
        <div class="d-flex flex-row pt-1">
          <div class="col-md-4"></div>
          <div class="col-md-7">
            <span class="fw-700 filterInput">{{ f.filterType }}</span>
          </div>
        </div>
        <div class="d-flex flex-row pt-2">
          <div class="col-md-4">
            <p-dropdown
              [options]="f.matchModeOptions"
              [(ngModel)]="f.matchModeValue"
              optionLabel="label"
              styleClass="col-md-11 drop-down-filter"
              dropdownIcon="drop-down-icon"
              id="f.matchModeValue"
            ></p-dropdown>
          </div>
          <div class="col-md-7">
            <input
              type="text"
              pInputText
              [(ngModel)]="f.filterInputValue"
              [placeholder]="f.placeHolderValue"
              class="p-inputtext globalFilterInput"
            />
          </div>
        </div>
      </div>
      <div *ngIf="f.filterMode === 'selectList'">
        <div class="d-flex flex-row pt-1">
          <span class="fw-700 filterInput">{{ f.filterType }}</span>
        </div>
        <div class="d-flex flex-row pt-2">
          <div class="filter-select-list">
            <p-dropdown
              [options]="f.matchModeOptions"
              [(ngModel)]="f.matchModeValue"
              [placeholder]="f.placeHolderValue ?? 'Select'"
              optionValue="value"
              optionLabel="label"
              styleClass="col-md-11 drop-down-filter"
              dropdownIcon="drop-down-icon"
              [id]="f.matchModeValue"
            ></p-dropdown>
          </div>
        </div>
      </div>
      <!-- Add this section for radio button -->
      <div *ngIf="f.filterMode === 'radio'">
        <div class="d-flex flex-row pt-1">
          <span class="fw-700 filterInput">{{ f.filterType }}</span>
        </div>
        <div class="d-flex flex-row pt-2">
          <label
            *ngFor="let option of f.radioOptions"
            class="col-md-4 radio-container"
          >
            <input
              type="radio"
              [name]="f.filterPropertyName"
              [value]="option.value"
              [checked]="f.selectedRadioValue === option.value"
              (change)="onRadioChange($event, index, option.value)"
            />
            {{ option.label }}
          </label>
        </div>
      </div>

      <div *ngIf="f.filterMode === 'dateFilter'">
        <div class="d-flex flex-row pt-1">
          <div class="col-md-7">
            <span class="fw-700 filterInput">{{ f.filterType }}</span>
          </div>
        </div>
        <div class="d-flex flex-row pt-2">
          <div class="col-md-4 datedp">
            <p-dropdown
              [options]="dateObjects"
              [(ngModel)]="f.selectedDateTypeValue"
              optionLabel="name"
              styleClass="col-md-12 drop-down-filter calendar-icon"
              dropdownIcon="drop-down-icon"
              id="dateFilter"
            ></p-dropdown>
          </div>
          <div
            [ngClass]="
              !(f.selectedDateTypeValue!.id === 5) ? 'd-none' : 'col-md-8'
            "
            (click)="onDateRangeClick($event)"
          >
            <p-calendar
              *ngIf="f.selectedDateTypeValue!.id === 5"
              [(ngModel)]="f.dateRange"
              selectionMode="range"
              [readonlyInput]="true"
              [styleClass]="'extended-width'"
              [showIcon]="true"
              [showButtonBar]="true"
              placeholder="{{ dateRangePlaceholderText }}"
            ></p-calendar>
          </div>
        </div>
      </div>
      <div *ngIf="f.seperatorRequired">
        <horizontal-line
          *ngIf="index < CheckFilterArrLength()"
        ></horizontal-line>
      </div>
    </div>
    <div *ngIf="isSearchIconVisible" class="d-flex flex-row-reverse pt-2">
      <button id="applyAll" class="button button-dark" (click)="ApplyFilter()">
        Apply
      </button>
    </div>
  </div>
</div>
