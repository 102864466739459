import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CustomHttpClientService } from 'src/app/infrastructure/services/custom-http-client.service';
import { UserSearchFilters } from '../models/UserSearchFilters';
import { UserListCollectionDto } from '../models/UserListCollectionDto';
import { FileResponse } from 'src/app/models/FileResponse';
import { FileService } from 'src/app/infrastructure/services/file.service';
import { CreateUpdateUserDto } from '../models/CreateUpdateUserDto';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(
    private http: CustomHttpClientService,
    private fileService: FileService,
  ) {}

  async GetUserListAsync(
    filters: UserSearchFilters,
    skip: number,
    take: number,
  ): Promise<UserListCollectionDto> {
    return lastValueFrom(
      this.http.post<UserListCollectionDto>(
        `api/user-management/list?skip=${skip}&take=${take}`,
        filters,
      ),
    );
  }

  async DownloadUserManagementReportTableFile(
    filters: UserSearchFilters,
    exportType: string,
    fileName: string,
  ): Promise<FileResponse> {
    const url = `api/user-management/download-report?filename=${fileName}&exportType=${exportType}`;
    return lastValueFrom(
      this.fileService.GetFileResponseWithInput(url, filters),
    );
  }

  async CreateUpdateUserAsync(createUpdateUser: CreateUpdateUserDto) {
    return lastValueFrom(
      this.http.post<CreateUpdateUserDto>(
        `api/user-management/createupdate`,
        createUpdateUser,
      ),
    );
  }

  async GetTaxIdDetailsAsync(taxId: string) {
    return lastValueFrom(
      this.http.get<number>(`api/user-management/taxid/${taxId}`),
    );
  }

  async VerifyUserEmailAlreadyExistAsync(email: string) {
    return lastValueFrom(
      this.http
        .get(`api/user-management/verify-email?email=${email}`)
        .pipe((data: any) => data),
    );
  }

  async VerifyTaxIdExistAsync(taxId: string, persona: string) {
    return lastValueFrom(
      this.http
        .get(
          `api/user-management/verify-taxId?taxId=${taxId}&persona=${persona}`,
        )
        .pipe((data: any) => data),
    );
  }

  async DeactivateReactivateSelectedUser(status: boolean, userId: number) {
    return lastValueFrom(
      this.http.patch(`api/user-management/user-status/${userId}`, status),
    );
  }

  async CaptureChangedPasswordForCurrentUserAsync() {
    return lastValueFrom(
      this.http.post(`api/user-management/change-password`, null),
    );
  }

  async ResetPasswordForSelectedUserAsync(userId: number) {
    return lastValueFrom(
      this.http.post(`api/user-management/reset-password`, userId),
    );
  }

  async DeleteUserAsync(userId: number, comment: string) {
    return lastValueFrom(
      this.http.delete(`api/user-management/delete-user/${userId}/${comment}`),
    );
  }
}
