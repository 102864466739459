<div class="row g-0" *ngIf="isLoggedIn && !isRouteRestricted" width>
  <div class="col-1 l-navbar sidebar-content">
    <app-sidebar></app-sidebar>
  </div>
  <div class="col-11 header-content">
    <app-header></app-header>
  </div>
</div>

<div class="message-container container-fluid">
  <ng-container *ngIf="messageDisplay">
    <message-panel
      [closeIconVisible]="closeIconVisible"
      [messageIconType]="messageIconType"
      [panelmessages]="panelmessages"
      [showAllMessages]="showAllMessages"
      (disposeEvent)="DisposeMessage()"
    ></message-panel>
  </ng-container>
</div>

<div class="content d-flex">
  <div
    class="flex-column"
    [ngClass]="
      isLoggedIn && !isRouteRestricted ? 'main-content container-fluid' : 'w100'
    "
  >
    <router-outlet></router-outlet>
  </div>
</div>
<div class="toast-container bottom-0 end-0">
  <div *ngFor="let toast of currentToasts; index as i">
    <app-toast
      [type]="toast.type"
      [title]="toast.title"
      [message]="toast.message"
      (disposeEvent)="Dispose(i)"
    ></app-toast>
  </div>
</div>

<!-- <div class="mt-5" *ngIf="isLoggedIn && !isRouteRestricted">
  <app-footer></app-footer>
</div> -->
<!-- <modal *ngIf="modalService.isModalActive"></modal> -->
<app-alert></app-alert>
<app-spinner></app-spinner>
<ng-container *ngIf="!isTermsAccepted && isLoggedIn">
  <tou [ShowCloseButton]="false" (Print)="Print()" (Accept)="Accept()"></tou>
</ng-container>
<ng-container *ngIf="isLoggedIn">
  <user-session-modal></user-session-modal>
</ng-container>
