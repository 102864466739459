import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppHeaderModule } from './app-header/app-header.module';
import { RegistrationSummaryModule } from 'src/app/modules/registration-summary/registration-summary.module';
import { HttpInterceptorService } from './infrastructure/interceptors/http-interceptor.service';
import { AppSidebarComponent } from './app-sidebar/app-sidebar.component';
import { CommonComponentsModule } from './modules/common-components/common-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  MsalGuardConfigFactory,
  MSalInterceptorFactory,
  CreateMSALInstance,
} from './infrastructure/msal/msal.configuration';
import { IPublicClientApplication } from '@azure/msal-browser';
import { ErrorHandlerService } from './services/error-handling.service';

let msalInstance: IPublicClientApplication;

export function msalInstanceFactory(): IPublicClientApplication {
  return msalInstance;
}

export function createMSALInstanceFactoryAsync(): () => Promise<any> {
  return async () => {
    return fetch('api/config-api/msal-configs')
      .then((response) => response.json())
      .then((json) => {
        const instance = CreateMSALInstance(json);
        msalInstance = instance;
      });
  };
}

@NgModule({
  declarations: [AppComponent, AppSidebarComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppHeaderModule,
    RegistrationSummaryModule,
    CommonComponentsModule,
    MsalModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: createMSALInstanceFactoryAsync,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: msalInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSalInterceptorFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    MsalService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
