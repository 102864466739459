import { Component, Input } from '@angular/core';

@Component({
  selector: 'se-preview-html-message',
  templateUrl: './se-preview-html-message.component.html',
  styleUrls: ['./se-preview-html-message.component.scss'],
})
export class SePreviewHtmlMessageComponent {
  @Input() message =
    'This is a HTML view. Downloaded document will be in PDF format and will have pagination as applicable.';
}
