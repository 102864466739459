import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

interface TimerConfig {
  durationInSeconds: number;
  resetOnMouseMove?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private timers = new Map<string, { remainingTime: number; worker: Worker }>();
  private timerSubjects = new Map<string, BehaviorSubject<number>>();
  private handleMouseMoveBound: ((event: MouseEvent) => void) | undefined;

  private InitializeWorker(sessionId: string, duration: number) {
    const worker = new Worker(
      '../../../assets/workers/session-timer-worker.js',
    );

    worker.onmessage = ({ data }) => {
      if (data.sessionId === sessionId) {
        const timerSubject = this.timerSubjects.get(sessionId);
        if (timerSubject) {
          timerSubject.next(data.time);

          const timerData = this.timers.get(sessionId);
          if (timerData) {
            timerData.remainingTime = data.time;

            if (data.time <= 0) {
              this.StopTimer(sessionId);
            }
          }
        }
      }
    };

    this.timers.set(sessionId, { remainingTime: duration, worker });
  }

  public StartTimer(sessionId: string, config: TimerConfig): void {
    if (this.timers.has(sessionId)) {
      this.StopTimer(sessionId);
    }

    this.InitializeWorker(sessionId, config.durationInSeconds);

    const timerSubject = new BehaviorSubject<number>(config.durationInSeconds);
    this.timerSubjects.set(sessionId, timerSubject);

    const worker = this.timers.get(sessionId)?.worker;
    worker?.postMessage({
      action: 'START',
      duration: config.durationInSeconds,
      sessionId: sessionId,
    });

    if (config.resetOnMouseMove) {
      this.BindMouseMoveEvent(sessionId, config.durationInSeconds);
    }
  }

  public ResetTimer(sessionId: string, duration: number): void {
    const timerData = this.timers.get(sessionId);
    if (timerData) {
      timerData.remainingTime = duration;

      const worker = timerData.worker;
      worker?.postMessage({
        action: 'START',
        duration: duration,
        sessionId: sessionId,
      });

      const timerSubject = this.timerSubjects.get(sessionId);
      timerSubject?.next(duration);
    }
  }

  public StopTimer(sessionId: string): void {
    const timerData = this.timers.get(sessionId);
    if (timerData) {
      timerData.worker.postMessage({ action: 'STOP' });
      timerData.worker.terminate();
      this.timers.delete(sessionId);
      this.timerSubjects.delete(sessionId);
    }

    if (this.timers.size === 0) {
      this.UnbindMouseMoveEvent();
    }
  }

  public GetTimerObservable(sessionId: string): Observable<number> {
    return (
      this.timerSubjects.get(sessionId)?.asObservable() ||
      new BehaviorSubject<number>(0).asObservable()
    );
  }

  private BindMouseMoveEvent(sessionId: string, duration: number) {
    if (!this.handleMouseMoveBound) {
      this.handleMouseMoveBound = () => {
        this.ResetTimer(sessionId, duration);
      };
      document.addEventListener('mousemove', this.handleMouseMoveBound);
    }
  }

  private UnbindMouseMoveEvent() {
    if (this.handleMouseMoveBound) {
      document.removeEventListener('mousemove', this.handleMouseMoveBound);
      this.handleMouseMoveBound = undefined;
    }
  }
}
