<div class="d-flex flex-column input-search-container">
  <div class="input-search-text">Search</div>
  <div class="d-flex input-search-box">
    <input type="text" [placeholder]="placeholder" (keyup)="search($event)" />
    <button-component
      [buttonClass]="'button button-dark btnImgLeft'"
      [isLeftImg]="false"
      [isRightImg]="false"
      Text="Search"
      [isButtonDisabled]="false"
      (OnClick)="applySearch()"
    ></button-component>
    <img class="search-icon" src="./assets/icons/search.svg" alt="" />
  </div>
</div>
